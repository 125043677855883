import React from 'react';

/**
 * Functional, stateless component
 * @param {any} props - Properties passed to this component
 * @returns JSX - A combination of JavaScript & HTML
 */
const Footer = props => (
  <footer className="uk-section uk-section-default uk-section-small uk-padding-remove-top">
    <div className="uk-container uk-container-large">
      <p className="uk-text-small uk-text-center">
        Powered by&nbsp;
        <a className="uk-link" href="https://ml6.eu/" target="_blank">
          ML6
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
