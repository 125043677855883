import React from 'react';
import Header from './containers/templates/Header';
import Main from './containers/templates/Main';
import Footer from './containers/templates/Footer';
import OffCanvas from './containers/templates/OffCanvas';

/**
 * React Redux
 * All container components need access to the Redux store so they can subscribe to it.
 * Provider makes the store available to all container components in the application without passing it explicitly.
 */
import { Provider } from 'react-redux';
import store from './store/store';

/**
 * Functional, stateless component
 * @returns JSX - A combination of JavaScript & HTML
 */
const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <div className="uk-offcanvas-content">
          <Header />
          <Main />
          <Footer />
        </div>
        <OffCanvas />
      </div>
    </Provider>
  );
};

export default App;
