import React, { Component } from 'react';
// import { PropTypes } from 'prop-types';

/**
 * React Redux
 * Imports
 */
import { connect } from 'react-redux';
import { loadData } from '../actions/searchActions';
import config from '../config';

/**
 * Util
 * Chunk
 */
import { chunkObject } from '../util/Group';

/**
 * React
 * Custom components
 */
import Sidebar from './Sidebar';
import ResultTable from '../components/ResultTable';
// import MapContainer from '../components/MapContainer';
import Pagination from '../components/Pagination';

/**
 * Stateful component
 */
class Home extends Component {
  /**
   * @constructor
   * @param {any} props - Properties passed to this component
   */
  constructor(props) {
    super(props);

    this.state = {
      searched: false,
      searchResult: null,
      searchError: false,
      loadError: false,
      dataLoaded: false,
      pageIndex: 0,
      auth: false
    };
  }

  /**
   * Lifecycle method
   * Called immediately after a component is mounted. Setting state here will trigger re-rendering.
   */
  componentDidMount() {
    // localStorage.removeItem('me-auth');
    let auth = localStorage.getItem('me-auth');
    if (!auth) {
      this.authenticate();
    } else {
      this.setState({
        auth: true
      });
    }
    this.props.loadData();
  }

  /**
   * Called when the component may be receiving new props.
   * React may call this even if props have not changed, so be sure to compare new and existing props if you only want to handle changes.
   * @param {any} nextProps - New props received from component.
   */
  componentWillReceiveProps(nextProps) {
    const stateKeys = Object.keys(this.state);
    stateKeys.map(key => this.mapNextPropsToState(nextProps, key));

    // Exception
    if (nextProps.searchResult) {
      let { searchResult } = nextProps;
      if (!searchResult.error && !searchResult.message) {
        if (searchResult && searchResult.payload) {
          searchResult = chunkObject(searchResult.payload, 10);
        }
        this.setState({
          searchResult,
          searchError: false
        });
      } else {
        this.setState({
          searchResult,
          searchError: true
        });
      }
    }
  }

  /**
   * Map 'nextProps' to state.
   * Update local state with new props.
   * @param {any} nextProps - New props received from component, passed from 'componentWillReceiveProps'.
   * @param {str} prop - Property key to map value from.
   */
  mapNextPropsToState(nextProps, prop = null) {
    if (prop) {
      if (nextProps[prop] && nextProps[prop] != this.props[prop]) {
        const value = nextProps[prop];

        this.setState({
          [prop]: value
        });
      }
    }
  }

  /**
   * Paginated
   * @param {int} i - Index of clicked page (page -1).
   */
  onPageChange(i) {
    const { pageIndex } = this.state || null;
    if (i !== pageIndex)
      this.setState({
        pageIndex: i
      });
  }

  /**
   * Authenticate & set localstorage
   */
  authenticate() {
    const modal = this.promptModal();
    modal.then(password => {
      if (password === config.password) {
        localStorage.setItem('me-auth', true);
        this.setState({
          auth: true
        });
      }
    });
  }

  /**
   * Trigger prompting modal
   * @param {str} msg - Prompt message
   * @param {str} value - Default value
   */
  promptModal(msg = 'Please, provide the password to continue:', value = '') {
    /* global UIkit */
    return UIkit.modal.prompt(msg, value);
  }

  /**
   * Clear search result when reset button pressed.
   */
  clearResult() {
    this.setState({
      searchResult: null,
      searched: false
    });
  }

  /**
   * Renders the component, re-renders on state changes
   * @returns JSX - A combination of JavaScript & HTML
   */
  render() {
    const { searchResult, pageIndex, searchError, loadError, auth } =
      this.state || null;
    return auth ? (
      <div className="uk-grid-divider uk-child-width-expand" data-uk-grid>
        <div className="uk-width-1-4@l">
          <Sidebar onReset={() => this.clearResult()} />
        </div>
        <div>
          <h3>Search Results</h3>
          {/* <div>
            <MapContainer />
          </div> */}
          <div className="uk-margin-top">
            <ResultTable
              searchResult={searchResult}
              page={pageIndex}
              loadError={loadError}
              searchError={searchError}
            />
          </div>
          <Pagination
            searchResult={searchResult}
            searchError={searchError}
            page={pageIndex}
            pageChange={i => this.onPageChange(i)}
          />
        </div>
      </div>
    ) : (
      <div className="uk-alert uk-margin-bottom uk-text-center uk-padding-medium">
        <h3>Unauthorized</h3>
        <p>Authentication failed!</p>
        <a className="uk-link" onClick={() => this.authenticate()}>
          Try&nbsp; again
        </a>
      </div>
    );
  }
}

/**
 * React has some built-in typechecking abilities.
 * To run typechecking on the props for a component, you can assign the special propTypes property
 * PropTypes exports a range of validators that can be used to make sure the data you receive is valid.
 * @example - { existingVariable: PropTypes.string }
 */
Home.propTypes = {};

/**
 * Map state to props
 * Make store state available as component's properties (this.props).
 * @param {any} state - Redux store's (current) state.
 * @returns {any} - Constructed object where the store's state gets assigned to component's properties.
 * @example - { keyOfChoice: state.exampleCollection.data } - usage: this.props.keyOfChoice
 */
const mapStateToProps = state => ({
  dataLoaded: state.searchCollection.dataLoaded,
  loadError: state.searchCollection.loadError,
  searchError: state.searchCollection.searchError,
  searchResult: state.searchCollection.searchResult
});

/**
 * Connect
 * Connects a React component to a Redux store.
 * @param {any} mapStateToProps - Maps the store's state to this component's properties.
 * @param {any} actions - Object of actions which are connected/made available to this component's properties (this.props).
 * @example connect(mapStateToProps, { fetchData })(Example1)
 */
export default connect(
  mapStateToProps,
  { loadData }
)(Home);
