import React from 'react';

const Pagination = props => {
  const { searchResult, page, searchError } = props || null;

  const onPageClick = i => {
    props.pageChange(i);
  };

  const pages =
    searchResult && !searchError
      ? searchResult.map((s, i) => {
          return (
            <li key={i} className={page === i ? 'uk-active' : ''}>
              <a onClick={() => onPageClick(i)}>{i + 1}</a>
            </li>
          );
        })
      : null;

  return (
    <div>
      {searchResult && (
        <ul
          className="uk-pagination uk-flex-right uk-margin-large-top uk-margin-large-bottom"
          data-uk-margin
        >
          <li>
            <a>
              <span className="uk-pagination-previous" />
            </a>
          </li>
          {pages}
          <li>
            <a>
              <span className="uk-pagination-next" />
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Pagination;
