import React from 'react';

const InfoComponent = props => {
  return (
    <div className="uk-alert uk-margin-large-bottom">
      <p>
        Fill in the form to start your search!
        <br />
        You can either search by address or by coordinates, not both.
      </p>
      <p>
        Please, make sure that the addresses you enter have been officially
        registered.
        <br />
        These can be verified at&nbsp;
        <a href="https://geopunt.be" target="_blank" className="uk-link">
          Geopunt
        </a>
        , where you can also retrieve the Lambert72 coordinates of any location
        in Flanders by clicking the map and selecting "Coördinaten".
      </p>
    </div>
  );
};

export default InfoComponent;
