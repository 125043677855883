/**
 * Grouping
 */

export const groupArray = (arr, prop, defaultValue = 0) => {
  const groups = arr.reduce((o, a) => {
    const m = a[prop] ? a[prop] : defaultValue; // Prop to group on

    o[m]
      ? o[m].data.push(a)
      : (o[m] = {
          key: m,
          data: [a]
        });
    return o;
  }, {});

  const result = Object.keys(groups).map(k => {
    return groups[k];
  });

  return result;
};

/** Chunk */

export const chunkArray = (arr, chunkSize = 10) => {
  let i, j, temp;
  for (i = 0, j = arr.length; i < j; i += chunkSize) {
    temp = arr.slice(i, i + chunkSize);
  }
  return temp;
};

export const chunkObject = (obj, chunkSize = 10) => {
  let values = Object.values(obj);
  let final = [];
  let counter = 0;
  let portion = {};

  for (const key in obj) {
    if (counter !== 0 && counter % chunkSize === 0) {
      final.push(portion);
      portion = {};
    }
    portion[key] = values[counter];
    counter++;
  }
  final.push(portion);
  return final;
};
