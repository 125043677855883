import React from 'react';
import SearchForm from '../components/SearchForm';

const Sidebar = props => {
  return (
    <div>
      <SearchForm onReset={props.onReset} />
    </div>
  );
};

export default Sidebar;
