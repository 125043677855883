/**
 * 2. The Redux store calls the reducer function you gave it.
 */

import { SEARCH_ERROR, SEARCH, LOAD_DATA, LOAD_ERROR } from '../actions/types';

const initialState = {
  searchResult: null,
  dataLoaded: false,
  loadError: false,
  searchError: false
};

/**
 * The store will pass two arguments to the reducer: the current state tree and the action.
 * @param {any} state - The current state tree.
 * @param {any} action - The action.
 */
export default function(state = initialState, action) {
  switch (action.type) {
    /**
     * SEARCH_ERROR, search errored
     */
    case SEARCH_ERROR:
      return {
        /**
         * ES6 spread operators, output everything that is currently inside the object/array.
         */
        ...state,
        searchError: action.payload
      };
    /**
     * SEARCH, search for result
     */
    case SEARCH:
      return {
        /**
         * ES6 spread operators, output everything that is currently inside the object/array.
         */
        ...state,
        searchResult: action.payload
      };
    /**
     * LOAD_ERROR
     */
    case LOAD_ERROR:
      return {
        /**
         * ES6 spread operators, output everything that is currently inside the object/array.
         */
        ...state,
        loadError: action.payload
      };
    /**
     * LOAD_DATA
     */
    case LOAD_DATA:
      return {
        /**
         * ES6 spread operators, output everything that is currently inside the object/array.
         */
        ...state,
        dataLoaded: action.payload
      };
    default:
      return state;
  }
}
