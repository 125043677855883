import { ONLINE_BACKEND_URL, LOCAL_BACKEND_URL } from './util/Constants';

/**
 * Check & return required environment variable.
 * @param {str} name - Name of environment variable to check & return.
 */
const requireProcessEnv = name => {
  if (!process.env[name]) {
    throw new Error('You must set the ' + name + ' environment variable');
  }
  return process.env[name];
};

/**
 * Project configuration
 * @exports - Export the configuration for target environment.
 */
const config = {
  all: {
    env: process.env.NODE_ENV || 'development',
    masterKey: requireProcessEnv('REACT_APP_MASTER_KEY'),
    password: requireProcessEnv('REACT_APP_PASSWORD')
  },
  test: {},
  development: {
    backendUrl: ONLINE_BACKEND_URL
  },
  production: {
    backendUrl: ONLINE_BACKEND_URL
  }
};

export default { ...config.all, ...config[config.all.env] };
