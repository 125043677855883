import React from 'react';
import InfoComponent from './InfoComponent';

const ResultTable = props => {
  const { searchResult, page, searchError, loadError } = props || null;
  const results = () => {
    const arr = [];
    for (const key in searchResult[page]) {
      if (searchResult[page].hasOwnProperty(key)) {
        const value = searchResult[page][key];
        arr.push(
          <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        );
      }
    }
    return arr;
  };

  const output =
    searchResult && !searchError ? (
      <table className="uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th>NACE Description</th>
            <th>SCORE</th>
          </tr>
        </thead>
        <tbody>{results()}</tbody>
      </table>
    ) : searchError ? (
      <div>
        <div className="uk-alert-danger" data-uk-alert>
          <p>
            No results for search criteria, {searchResult.error.toLowerCase()}
          </p>
        </div>
        <InfoComponent />
      </div>
    ) : loadError ? (
      <div>
        <div className="uk-alert-danger" data-uk-alert>
          <p>Error loading data, please contact administrator.</p>
        </div>
        <InfoComponent />
      </div>
    ) : (
      <InfoComponent />
    );

  return <div className="uk-overflow-auto">{output}</div>;
};

export default ResultTable;
