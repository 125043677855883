import React, { Component } from 'react';

/**
 * React Redux
 * Imports
 */
import { connect } from 'react-redux';
import { search } from '../actions/searchActions';
import config from '../config';

/**
 * React
 * Custom components
 */
import DefaultModal from './DefaultModal';

/**
 * Stateful component
 */
class SearchForm extends Component {
  constructor(props) {
    super(props);

    /**
     * Init local state
     */
    this.state = {
      street: '',
      house_number: '',
      post_code: '',
      municipality: '',
      lambert_x: '',
      lambert_y: '',
      area_building: 500,
      area: 1100,
      n_parcels: 1,
      n_companies: 1,
      sliderRanges: { min: 1, max: 20000, step: 1 },
      dataLoaded: false,
      searched: false,
      searchError: false,
      loadError: false,
      searchResult: null,
      disableAddressFields: false,
      disableCoordinatesFields: false
    };
  }

  /**
   * Lifecycle method
   * Called immediately after a component is mounted. Setting state here will trigger re-rendering.
   */
  componentDidMount() {}

  /**
   * Map 'nextProps' to state.
   * Update local state with new props.
   * @param {any} nextProps - New props received from component, passed from 'componentWillReceiveProps'.
   * @param {str} prop - Property key to map value from.
   */
  componentWillReceiveProps(nextProps) {
    const stateKeys = Object.keys(this.state);
    stateKeys.map(key => this.mapNextPropsToState(nextProps, key));

    // Exception: set searched false if searchError or searchResult
    if (nextProps.searchError || nextProps.searchResult) {
      this.setState({
        searched: false
      });
    }
  }

  /**
   * Map 'nextProps' to state.
   * Update local state with new props.
   * @param {any} nextProps - New props received from component, passed from 'componentWillReceiveProps'.
   * @param {str} prop - Property key to map value from.
   */
  mapNextPropsToState(nextProps, prop = null) {
    if (prop) {
      if (nextProps[prop] && nextProps[prop] != this.props[prop]) {
        const value = nextProps[prop];

        this.setState({
          [prop]: value
        });
      }
    }
  }

  /**
   * Init search on form submit
   * @param {any} e - HTML DOM Event
   */
  onFormSubmit(e) {
    e.preventDefault();

    const {
      street,
      house_number,
      post_code,
      municipality,
      lambert_x,
      lambert_y,
      area_building,
      area,
      n_parcels,
      n_companies
    } = this.state || null;

    const searchCriteria = {
      street,
      house_number,
      post_code,
      municipality,
      lambert_x,
      lambert_y,
      area_building,
      area,
      n_parcels,
      n_companies
    };

    /**
     * Validation
     * Require address or coordinates
     * Municipality not required
     *
     */
    let validationError;
    if (
      (street && house_number && (municipality || post_code)) ||
      (lambert_x && lambert_y)
    ) {
      validationError = { error: false, msg: null };
    } else if (
      street &&
      house_number &&
      (municipality || post_code) &&
      (lambert_x && lambert_y)
    ) {
      validationError = {
        error: false,
        msg:
          'You filled in both address and coordinate related fields! Coordinates are prioritized.'
      };
    } else {
      validationError = {
        error: true,
        msg:
          'Please fill in either all address- or all coordinate related fields!'
      };
    }

    if (!validationError.error) {
      // console.log(
      //   JSON.stringify({
      //     input: searchCriteria,
      //     access_token: config.masterKey
      //   })
      // );
      this.props.search(searchCriteria);
      this.setState({
        searched: true
      });
      if (validationError.msg) this.alert(validationError.msg);
    } else {
      this.alert(validationError.msg);
    }
  }

  /**
   * Triggered on input change.
   * @param {any} target - HTML DOM Event target
   */
  onInputChange({ target }) {
    // Exceptional behavior, disable fields when others are altered.
    const addressFields = [
      'street',
      'house_number',
      'post_code',
      'municipality'
    ];
    const coordinatesFields = ['lambert_x', 'lambert_y'];

    let disableAddressFields = false;
    let disableCoordinatesFields = false;
    let resetFields = {};
    if (addressFields.indexOf(target.name) > -1 && target.value != '') {
      // Disable coordinates fields.
      // & reset
      disableCoordinatesFields = true;
      coordinatesFields.map(field => (resetFields[field] = ''));
    } else if (
      coordinatesFields.indexOf(target.name) > -1 &&
      target.value != ''
    ) {
      // Disable address fields.
      // & reset
      disableAddressFields = true;
      addressFields.map(field => (resetFields[field] = ''));
    }

    /*
      Because we named the inputs to match their
      corresponding values in state, it's
      super easy to update the state
    */
    this.setState({
      [target.name]: target.value,
      disableCoordinatesFields,
      disableAddressFields,
      ...resetFields
    });
  }

  /**
   * Reset form
   */
  onResetForm() {
    this.setState({
      street: '',
      house_number: '',
      post_code: '',
      municipality: '',
      lambert_x: '',
      lambert_y: '',
      area_building: 500,
      area: 1100,
      n_parcels: 1,
      n_companies: 1,
      disableCoordinatesFields: false,
      disableAddressFields: false
    });

    // Reset search result on parent via props
    this.props.onReset();
  }

  /**
   * Trigger alert modal.
   * @param {str} msg - Alert message.
   */
  alert(msg) {
    /* global UIkit */
    UIkit.modal.alert(msg);
  }

  /**
   * Render component
   */
  render() {
    let {
      street,
      house_number,
      post_code,
      municipality,
      lambert_x,
      lambert_y,
      area_building,
      area,
      n_parcels,
      n_companies,
      sliderRanges,
      searched,
      loadError,
      disableAddressFields,
      disableCoordinatesFields
    } = this.state || null;

    /**
     * Search Criteria
     * Address or coordinates
     * 
      street = 'Mariakerkegeest'
      house_number = '8'
      post_code = '9030'
      municipality = 'pariakerke'
      lambert_x = 0  # Lambert72 x coordinate (m)
      lambert_y = 0  # Lambert72 y coordinate (m)
      area_building = 1500  # square meters
      area = 3000  # square meters, sum of all parcels
      n_parcels = 1
      n_companies = 1
     */
    const error = loadError;

    const defaultModel = UIkit.modal('#modal-default');
    /* global UIkit */
    if (searched && !loadError) {
      defaultModel.show();
    } else {
      if (defaultModel) defaultModel.hide();
    }

    const btnText =
      searched && !loadError
        ? 'Loading...'
        : loadError
          ? 'Load Error'
          : 'Search';

    return (
      <div>
        <h3>Search</h3>
        <form
          className="uk-grid-small uk-form-stacked"
          data-uk-grid
          onSubmit={e => this.onFormSubmit(e)}
        >
          <div className="uk-width-1-1@s">
            <small className="uk-text-muted">Search by address</small>
          </div>
          <div className="uk-width-2-3@s uk-width-1-2@m uk-width-2-3@xl">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Street
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                name="street"
                value={street}
                placeholder="Street name"
                disabled={disableAddressFields}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-3@s uk-width-1-2@m uk-width-1-3@xl">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Nr.
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                name="house_number"
                value={house_number}
                placeholder="House nr."
                disabled={disableAddressFields}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-2-3@s uk-width-1-2@m uk-width-2-3@xl">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Municipality
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                name="municipality"
                value={municipality}
                placeholder="Name of municipality"
                disabled={disableAddressFields}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-3@s uk-width-1-2@m uk-width-1-3@xl">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Postal code
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="number"
                name="post_code"
                value={post_code}
                placeholder="9000"
                disabled={disableAddressFields}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-1@s">
            <hr />
          </div>
          <div className="uk-width-1-1@s">
            <small className="uk-text-muted">Or search by coordinates</small>
          </div>
          <div className="uk-width-1-2@s">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Lambert72 X
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                name="lambert_x"
                value={lambert_x}
                placeholder="0"
                disabled={disableCoordinatesFields}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Lambert72 Y
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="text"
                name="lambert_y"
                value={lambert_y}
                placeholder="0"
                disabled={disableCoordinatesFields}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-1@s">
            <hr />
          </div>
          <div className="uk-width-1-2@s uk-width-1-2@m uk-width-1-1@l uk-width-1-2@xl">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Plot area (m²)
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                name="area"
                type="number"
                value={area}
                min={sliderRanges.min}
                max={sliderRanges.max}
                step={sliderRanges.step}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-2@m uk-width-1-1@l uk-width-1-2@xl">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Ground floor area (m²)
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                name="area_building"
                type="number"
                value={area_building}
                min={sliderRanges.min}
                max={sliderRanges.max}
                step={sliderRanges.step}
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>

          <div className="uk-width-1-2@s uk-width-1-2@m uk-width-1-1@l uk-width-1-2@xl">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Number of parcels
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="number"
                name="n_parcels"
                value={n_parcels}
                min={sliderRanges.min}
                placeholder="1"
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>
          <div className="uk-width-1-2@s uk-width-1-2@m uk-width-1-1@l uk-width-1-2@xl">
            <label className="uk-form-label" htmlFor="form-stacked-select">
              Number of companies
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                type="number"
                name="n_companies"
                value={n_companies}
                min={sliderRanges.min}
                placeholder="1"
                onChange={e => this.onInputChange(e)}
              />
            </div>
          </div>

          <div className="uk-margin-medium uk-width-1-1@s">
            <input
              type="reset"
              value="Reset"
              className="uk-button uk-button-default uk-width-1-2"
              onClick={() => this.onResetForm()}
            />
            <input
              type="submit"
              className={`uk-button uk-button-${
                error ? 'default' : 'primary'
              } uk-width-1-2`}
              value={btnText}
              disabled={searched || error}
            />
          </div>
        </form>
        <DefaultModal
          title={'Loading...'}
          body={`Please wait while we're searching.`}
        />
      </div>
    );
  }
}

/**
 * React has some built-in typechecking abilities.
 * To run typechecking on the props for a component, you can assign the special propTypes property
 * PropTypes exports a range of validators that can be used to make sure the data you receive is valid.
 * @example - { existingVariable: PropTypes.string }
 */
SearchForm.propTypes = {};

/**
 * Map state to props
 * Make store state available as component's properties (this.props).
 * @param {any} state - Redux store's (current) state.
 * @returns {any} - Constructed object where the store's state gets assigned to component's properties.
 * @example - { keyOfChoice: state.exampleCollection.data } - usage: this.props.keyOfChoice
 */
const mapStateToProps = state => ({
  dataLoaded: state.searchCollection.dataLoaded,
  loadError: state.searchCollection.loadError,
  searchError: state.searchCollection.searchError,
  searchResult: state.searchCollection.searchResult
});

/**
 * Connect
 * Connects a React component to a Redux store.
 * @param {any} mapStateToProps - Maps the store's state to this component's properties.
 * @param {any} actions - Object of actions which are connected/made available to this component's properties (this.props).
 * @example connect(mapStateToProps, { fetchData })(Example1)
 */
export default connect(
  mapStateToProps,
  { search }
)(SearchForm);
