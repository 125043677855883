/**
 * 1. You call store.dispatch(action).
 * Actions are payloads of information that send data from your application to your store (dispatching).
 * They are the only source of information for the store.
 * An action is a plain object describing what happened. For example: { type: 'FETCH_USER_SUCCESS', response: { id: 3, name: 'Mary' } }
 *
 * Redux Thunk middleware makes an async data flow, asynchronous actions supported.
 */

import config from '../config';
import { SEARCH_ERROR, SEARCH, LOAD_DATA, LOAD_ERROR } from './types';

/**
 * Asynchronously fetches data and dispatches it to the reducer.
 */
export const loadData = () => dispatch => {
  /**
   * The Fetch API provides an interface for fetching resources (including across the network).
   * It will seem familiar to anyone who has used XMLHttpRequest, but the new API provides a more powerful and flexible feature set.
   * Other similar, acceptable packages are 'request-promise' & 'axios'.
   */
  fetch(`${config.backendUrl}/load-data?access_token=${config.masterKey}`)
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LOAD_DATA,
        payload: true
      })
    )
    .catch(err => {
      // console.error(err); // Logging the error, exposes the master key!
      dispatch({
        type: LOAD_ERROR,
        payload: true
      });
    });
};

/**
 * Asynchronously fetches data and dispatches it to the reducer.
 */
export const search = criteria => dispatch => {
  /**
   * The Fetch API provides an interface for fetching resources (including across the network).
   * It will seem familiar to anyone who has used XMLHttpRequest, but the new API provides a more powerful and flexible feature set.
   * Other similar, acceptable packages are 'request-promise' & 'axios'.
   */
  fetch(`${config.backendUrl}/predict-for-unseen-data`, {
    method: 'POST',
    body: JSON.stringify({ input: criteria, access_token: config.masterKey }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: SEARCH,
        payload: data
      })
    )
    .catch(err => {
      // console.error(err); // Logging the error, exposes the master key!
      dispatch({
        type: SEARCH_ERROR,
        payload: true
      });
    });
};
