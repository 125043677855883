import React from 'react';

const DefaultModal = props => {
  return (
    <div id="modal-default" data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body">
        <button
          className="uk-modal-close-default"
          type="button"
          data-uk-close
        />
        <h2 className="uk-modal-title">{props.title || ''}</h2>
        <p>{props.body || ''}</p>
      </div>
    </div>
  );
};

export default DefaultModal;
