import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Home';

/**
 * Functional, stateless component
 * @param {any} props - Properties passed to this component
 * @returns JSX - A combination of JavaScript & HTML
 */
const Main = props => (
  <main>
    <section className="uk-section uk-padding-remove-bottom">
      <div className="uk-container uk-container-large">
        {/** Router outlet */}
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </div>
    </section>
  </main>
);

export default Main;
