import React from 'react';
import Link from 'react-router-dom/Link';

/**
 * Functional, stateless component
 * @param {any} props - Properties passed to this component
 * @returns JSX - A combination of JavaScript & HTML
 */
const Header = props => (
  <header>
    <div className="uk-container uk-container-large">
      <nav className="uk-navbar-transparent" data-uk-navbar>
        <div className="uk-navbar-left">
          <a className="uk-navbar-item uk-logo" href="/">
            <img
              data-uk-img
              src="./assets/images/logos/march.png"
              alt="ML6 - logo"
            />
          </a>
        </div>
        {/* <div className="uk-navbar-center uk-visible@m">
          <ul className="uk-navbar-nav">
            <li><Link to="/example1">Page 1</Link></li>
            <li><Link to="/example2">Page 2</Link></li>
            <li>
              <a href="#">Dropdown <span data-uk-icon="icon: triangle-down"></span></a>
              <div className="uk-navbar-dropdown">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li><a href="#">Sub Page 1</a></li>
                  <li><a href="#">Sub Page 2</a></li>
                  <li><a href="#">Sub Page 3</a></li>
                  <li><a href="#">Sub Page 4</a></li>
                  <li><a href="#">Sub Page 5</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            <li>
              <a href="#" data-uk-icon="icon:user"></a>
              <div className="uk-navbar-dropdown uk-navbar-dropdown-bottom-left">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li className="uk-nav-header uk-text-small uk-text-primary">YOUR ACCOUNT</li>
                  <li><a href="#"><span data-uk-icon="icon: info"></span> Summary</a></li>
                  <li><a href="#"><span data-uk-icon="icon: refresh"></span> Edit</a></li>
                  <li><a href="#"><span data-uk-icon="icon: settings"></span> Configuration</a></li>
                  <li className="uk-nav-divider"></li>
                  <li><a href="#"><span data-uk-icon="icon: image"></span> Your Pictures</a></li>
                  <li className="uk-nav-divider"></li>
                  <li><a href="#"><span data-uk-icon="icon: sign-out"></span> Logout</a></li>

                </ul>
              </div>
            </li>
            <li className="uk-hidden@m"><a className="uk-navbar-toggle" data-uk-toggle="target: #offcanvas-nav" data-uk-navbar-toggle-icon="icon: menu"></a></li>
          </ul>
        </div> */}
      </nav>
    </div>
  </header>
);

export default Header;
