/**
 * 4. The Redux store saves the complete state tree returned by the root reducer.
 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

/**
 * Redux Thunk Lets us dispatch() functions -> supports async data flow, asynchronous actions.
 */
const middleware = [thunk, logger];
const initialState = {};

/**
  * The Store is the object that brings them together (actions & reducers -> updating state).
  * The store has the following responsibilities:
   * Holds application state;
   * Allows access to state via getState();
   * Allows state to be updated via dispatch(action);
   * Registers listeners via subscribe(listener);
   * Handles unregistering of listeners via the function returned by subscribe(listener).
 */
const store = createStore(
  rootReducer, 
  initialState,
  /**
   * Must have Chrome extension: Redux DevTools for debugging application's state changes.
   */
  composeWithDevTools(
    applyMiddleware(...middleware)
  )
);

export default store;
