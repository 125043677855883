/**
 * SEARCH TYPES
 * @exports {str} SEARCH_ERROR - Type constant for search error action.
 * @exports {str} SEARCH - Type constant for posting search criteria action.
 * @exports {str} LOAD_DATA - Type constant for data loading action.
 * @exports {str} LOAD_ERROR - Type constant for data erroring action.
 */
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH = 'SEARCH';
export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_ERROR = 'LOAD_ERROR';
